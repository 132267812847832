import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col, Modal } from 'react-bootstrap';

import Layout from '../../components/layout/layout';
// import Seo from '../../components/seo/seo';
import Img from 'gatsby-image';
// import LayoutMap from '../../components/wordPress/layouts/layoutMap/layoutMap';
import ImageCarousel from '../../components/imageCarousel/imageCarousel';
import SectionHero from '../../components/wordPress/sections/sectionHero/sectionHero';
import Seo from '../../components/seo/seo';
const OurLocationPage = ({ data }) => {

  // Map ACF content to variable
  const page = data.wpPage.pageOurLocation;

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openCarouselModal = (imageIndex) => {
    setCarouselIndex(imageIndex);
    handleShow();
  };

  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/our-location/`}
      />

      <>
        <SectionHero
          buttonDefaults={data.wp.globalContent.globalButtons.buttonSettings}
          sectionContent={page.locationHeader}
        />

        <section className={`py-5`}>
          <Container>
            <Row className={`justify-content-center text-center`}>
              <Col xs={11} md={8}>
                <h2>A Safe Haven</h2>
                <p>With locations in Sacramento and Fair Oaks, California, we give clients a safe, comfortable haven where they can focus solely on recovery. Serving just a few clients at a time to ensure dedicated attention and privacy, our facilities offer room to breathe.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`pb-5`}>
          <Container>
            <Row className={`no-gutters justify-content-center`}>
              {page.gallery.map((image, index) => (
                <Col
                  xs={6}
                  md={3}
                  onClick={() => openCarouselModal(index)}
                  key={index}
                  style={{ cursor: 'pointer' }}
                >
                  <Img
                    fluid={image.image.localFile.childImageSharp.fluid}
                    alt={image.image.altText}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        {/* <section>
          <LayoutMap map={data.wp.globalContent.globalContact} />
        </section> */}
      </>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modal-90w"
      >
        <ImageCarousel
          carouselImages={page.gallery}
          carouselIndex={carouselIndex}
        />
      </Modal>
    </Layout>
  );
};

export const query = graphql`
    query {
        wpPage(databaseId: { eq: 256 }) {
            title
            seo {
                metaTitle
                metaDesc
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    localFile {
                        publicURL
                    }
                }
            }
            pageOurLocation {
                locationHeader {
                    fieldGroupName
                    content
                    htmlClass
                    background {
                        backgroundType
                        backgroundImage {
                            localFile {
                                ...imageFluidLarge
                            }
                        }
                        backgroundVideo {
                            localFile {
                                publicURL
                            }
                        }
                        backgroundVideoCover {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    buttonLayout {
                        showButton
                        overrideButtonSettings
                        buttonSettings {
                            buttonText
                            linkType
                            internalLink {
                                ... on WpPage {
                                    uri
                                }
                                ... on WpPost {
                                    uri
                                }
                            }
                            externalLink
                            htmlClass
                        }
                    }
                }
                locationContent
                gallery {
                    image {
                        localFile {
                            ...imageFluidLarge
                        }
                        altText
                    }
                }
            }
        }
        wp {
            globalContent {
                globalButtons {
                    buttonSettings {
                        buttonText
                        linkType
                        internalLink {
                            ... on WpPost {
                                id
                                uri
                            }
                            ... on WpPage {
                                id
                                uri
                            }
                        }
                        externalLink
                        htmlClass
                    }
                }
                globalContact {
                    map {
                        latitude
                        longitude
                        zoom
                    }
                }
            }
        }
    }
`;

export default OurLocationPage;
