import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';

const Styles = styled.div({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',

  svg: {
    height: rhythm(3 / 2),
  },

  '.col': {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

export default Styles;
