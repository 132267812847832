import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Row, Col, Container, Carousel } from 'react-bootstrap';

import Styles from './imageCarousel.styles';

const ImageCarousel = ({ carouselIndex, carouselImages }) => {
  const [index, setIndex] = useState(carouselIndex);
  const [images] = useState(carouselImages);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Styles>
      <Container fluid={true}>
        <Row className="justify-content-center">
          <Col className="px-0">
            <Carousel
              interval={null}
              activeIndex={index}
              onSelect={handleSelect}
            >
              {images.map((image, index) => (
                <Carousel.Item key={index}>
                  <Img
                    fluid={image.image.localFile.childImageSharp.fluid}
                    alt={image.altText}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Styles>
  );
};

export default ImageCarousel;

ImageCarousel.propTypes = {
  carouselIndex: PropTypes.number.isRequired,
  carouselImages: PropTypes.array.isRequired,
};
